export const logoPngs = [
  "/adidas.png",
  "/americaneagle.png",
  "/anf.png",
  "/ariat.png",
  "/athleta.png",
  "/bowflex.png",
  "/brooks.png",
  "/champion.png",
  "/columbia.png",
  "/converse.png",
  "/dakine.png",
  "/dicks.png",
  "/eddiebauer.png",
  "/gap.png",
  "/hanes.png",
  "/hannaandersson.png",
  "/hokaoneone.png",
  "/hollister.png",
  "/levis.png",
  "/lululemon.png",
  "/merrel.png",
  "/modcloth.png",
  "/newbalance.png",
  "/nike.png",
  "/nordstrom.png",
  "/northface.png",
  "/oldnavy.png",
  "/patagonia.png",
  "/pendleton.png",
  "/puma.png",
  "/reebok.png",
  "/rei.png",
  "/skechers.png",
  "/sorel.png",
  "/squarespace.png",
  "/timberland.png",
  "/ua.png",
  "/urbanoutfitters.png",
]
