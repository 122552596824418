import "./App.css"
import FormSubmission from "./components/FormSubmission"
import { useState, useEffect, useRef } from "react"
import ClientLogos from "./components/ClientLogos"
import ClientLogosTwo from "./components/ClientLogosTwo"
import SiteFooter from "./components/SiteFooter"
function App() {
  // const [entered, setEntered] = useState(false)
  // const prevScrollY = useRef(0)
  // const [prevDir, setPrevDir] = useState(0)

  // const slidersRef = useRef(null)
  // const sloganRef = useRef(null)
  useEffect(() => {
    // const sloganRefHeight = sloganRef.current.offsetHeight
    // const slidersRefHeight = slidersRef.current.offsetHeight

    // const onScroll = () => {
    //   const currentScrollY = window.scrollY

    //   currentScrollY - prevScrollY.current > 0 ? setPrevDir(-1) : setPrevDir(1)
    //   prevScrollY.current = currentScrollY
    //   if (!entered) {
    //     if (prevScrollY.current > sloganRefHeight - 100) {
    //       document.querySelectorAll(".slider-content")[0].classList.add("animate-left")
    //       document.querySelectorAll(".slider-content")[1].classList.add("animate-left")
    //       document.querySelectorAll(".slider-image")[0].classList.add("animate-right")
    //       document.querySelectorAll(".slider-image")[1].classList.add("animate-right")
    //       document.querySelectorAll(".logo-slider")[0].classList.add("shrink")
    //       document.querySelectorAll(".logo-slider")[1].classList.add("shrink")
    //       document.querySelector(".triangles-wrapper").classList.add("appear-opacity")
    //     } else {
    //       document.querySelectorAll(".slider-content")[0].classList.remove("animate-left")
    //       document.querySelectorAll(".slider-content")[1].classList.remove("animate-left")
    //       document.querySelectorAll(".slider-image")[0].classList.remove("animate-right")
    //       document.querySelectorAll(".slider-image")[1].classList.remove("animate-right")
    //       document.querySelectorAll(".logo-slider")[0].classList.remove("shrink")
    //       document.querySelectorAll(".logo-slider")[1].classList.remove("shrink")
    //       document.querySelector(".triangles-wrapper").classList.remove("appear-opacity")
    //     }
    //     // if (prevScrollY.current > sloganRefHeight - 600 && prevScrollY.current < sloganRefHeight - 100) {
    //     //   slidersRef.current.style.top = `-${prevScrollY.current / 15 + 30}vh`
    //     // } else {
    //     //   slidersRef.current.style.top = "20vh"
    //     // }
    //   }

    //   if (entered && prevScrollY.current < slidersRefHeight) {
    //     const mainEl = document.querySelector("div.main-content")
    //     const formEl = document.querySelector("div.form-wrapper")
    //     const clientLogos = document.querySelector("div.client-logos-wrapper")
    //     const siteFooter = document.querySelector("div.site-footer-wrapper")
    //     mainEl.classList.add("removed")
    //     formEl.classList.add("removed")
    //     clientLogos.classList.add("removed")
    //     siteFooter.classList.add("removed")
    //     setEntered(!entered)
    //     mainEl.style.padding = "300px 0 0 var(--pad-side)"
    //     slidersRef.current.style.padding = "40px 0 300px 0"
    //   }
    // }

    // window.removeEventListener("scroll", onScroll)
    // window.addEventListener("scroll", onScroll, { passive: true })
    return () => {
      // document.querySelectorAll(".slider-content")[0].classList.remove("animate-left")
      // document.querySelectorAll(".slider-content")[1].classList.remove("animate-left")
      // document.querySelectorAll(".slider-image")[0].classList.remove("animate-right")
      // document.querySelectorAll(".slider-image")[1].classList.remove("animate-right")
      // document.querySelectorAll(".logo-slider")[0].classList.remove("shrink")
      // document.querySelectorAll(".logo-slider")[1].classList.remove("shrink")
      // document.querySelector(".triangles-wrapper").classList.remove("appear-opacity")
      // window.removeEventListener("scroll", onScroll)
    }
  }, [])

  const handleOnEnter = () => {
    // setEntered(!entered)
    // const mainEl = document.querySelector("div.main-content")
    // const formEl = document.querySelector("div.form-wrapper")
    // const clientLogos = document.querySelector("div.slide.client-logos-wrapper")
    // const siteFooter = document.querySelector("div.site-footer-wrapper")
    // mainEl.classList.remove("removed")
    // formEl.classList.remove("removed")
    // clientLogos.classList.remove("removed")
    // siteFooter.classList.remove("removed")
    // slidersRef.current.style.padding = "0 0 0 0"
    // mainEl.style.padding = "0 0 0 var(--pad-side)"
  }

  return (
    <div className="App">
      <svg width="0" height="0" clipPathUnits="objectBoundingBox"></svg>
      {/* <div className="blocker"></div> */}
      <div className="collage-wrapper">
        <div className="collage">
          <img className="collage-photo first" src="/optk_denim.jpg" alt="optk_denim" />
        </div>
        <div className="collage">
          <img className="collage-photo second" src="/optk_braces.jpeg" alt="optk_braces" />
        </div>
        <div className="collage">
          <img className="collage-photo third" src="/optk_plantshop.jpg" alt="optk_plantshop" />
        </div>
      </div>

      <div className="slide slogan">
        <h1 id="outside-quotation">"</h1>
        <h1>hey,</h1>

        <h1>we've been waiting for you..."</h1>
      </div>
      <div className="slide optk-logo">
        <div className="slider-content">
          <p>The speakeasy of model agencies, OPTK is a diffusion line of fresh, unique faces, that are accessible by invite only.</p>
          <p>The OPTK model is not yet represented, elusive, indefinable, and undeniably arresting. They’re more than a pretty face.</p>
          <p>And the OPTK client is craving models that catch eyes, start conversations, and break down barriers.</p>
        </div>
        <div className="logos-wrapper">
          <img className="slider-image" src="/optk_logo.png" alt="OP" />
          <img className="slider-image" src="/optk_logo.png" alt="TK" />
        </div>
      </div>
      <img className="triangles-logo" src="/triangles.svg"></img>

      <div className="slide main-content">
        <h2>join us</h2>
        <img src="/triangles.svg"></img>
        <p>We represent the thems, hims, hers, and all those unique.</p>
        <p>Are you in?</p>
        <p>Please use the form below to submit some basic info and snapshots.</p>
        <p>We wanna see the authentic you, so you do YOU!</p>
        <br />
        <p>
          (please, no filters, sunglasses, facetune or photoshop <span id="smiley">☺</span>)
        </p>
      </div>

      <div className="slide form-wrapper">
        <FormSubmission />
      </div>
      <div className="slide client-logos-wrapper">
        <ClientLogosTwo />
      </div>
      <div className="slide site-footer-wrapper">
        <SiteFooter />
      </div>
    </div>
  )
}

export default App
