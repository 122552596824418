import { useEffect, useState } from "react"
export default function ThankYou({ setIsSubmitted, isContact = false }) {
  return (
    <div className="thankyou-wrapper">
      <div className="thankyou-container">
        <div onClick={setIsSubmitted} className="x-icon">
          <img alt="line" src="/line.svg" className="line" />
          <img alt="line" src="/line.svg" className="line" />
        </div>
        <div className="innerbox">
          <h1 id="submission-header">thanks for your submission</h1>
          <h1 id="contact-header">thanks, we'll be in touch...</h1>
          <p>we ask that you do not follow up regarding your submission. as a boutique - style agency, we only bring on a select few to join our roster. if we do not reply within a few days, we feel that we are not the right agency for you at this time.</p>
          <div className="triangles">
            <img alt="triangle logo" src="/triangles.svg" />
          </div>
        </div>
      </div>
      <div className="bg-overlay"></div>
      <style jsx>
        {`
          * {
            font-family: var(--font-1);
          }
          .thankyou-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100vw;
            z-index: 1500;
            height: 100vh;
            margin: 0 auto;
            top: 0;
            position: fixed;
          }
          .thankyou-container {
            position: absolute;
            top: ${isContact ? "25vh" : "15vh"};
            max-height: 800px;
            z-index: 1;
          }
          .x-icon {
            z-index: 2;
            cursor: pointer;
            left: 30px;
            top: 30px;
            position: absolute;
            width: 50px;
            height: 50px;
          }
          .x-icon > * {
            outline: 1.5px solid black;
            outline-offset: -1px;
            top: 25px;
            left: 0px;
            width: inherit;
            position: absolute;
          }
          .line:nth-child(1) {
            transform: rotate(45deg);
          }
          .line:nth-child(2) {
            transform: rotate(-45deg);
          }
          h1 {
            font-size: 2rem;
            font-weight: 500;
          }
          #submission-header {
            display: ${isContact ? "none" : "block"};
          }
          #contact-header {
            display: ${isContact ? "block" : "none"};
            margin-bottom: ${isContact ? "30px" : ""};
          }
          .innerbox {
            text-align: center;
            border: 100px solid var(--oatmilk);
            padding: calc(var(--pad-side) * 0.5);
            background-color: white;
            align-items: center;
            display: flex;

            flex-direction: column;
            gap: 5px;
          }
          .bg-overlay {
            background-color: black;
            height: 100vh;
            width: 100vw;
            opacity: 0.8;
          }
          p {
            display: ${isContact ? "none" : "block"};
            font-family: var(--font-1);
            font-weight: 400;
            text-align: justify;
            text-align-last: center;
            max-width: 475px;
            margin: 25px;
            line-height: 20x;
          }
          .triangles img {
            padding-top: 25px;
            width: 175px;
          }
          @media (max-width: 850px) {
            .thankyou-container {
              top: 20vh;
              width: ${isContact ? "80vw" : "80vw"};
              left: ${isContact ? "10vw" : "10vw"};
            }
            h1 {
              font-size: 1.5rem;
            }
            .innerbox {
              border-width: 70px;
              font-size: 0.75rem;
            }
            .triangles img {
              width: 150px;
            }

            .x-icon {
              left: 15px;
              top: 15px;
              position: absolute;
              width: 40px;
              height: 40px;
            }
          }
          @media (max-width: 550px) {
            .innerbox {
              border-width: 50px;
              font-size: 0.75rem;
            }
            p {
              margin-left: unset;
              margin-right: unset;
            }
            .x-icon {
              left: 5px;
              top: 5px;
              position: absolute;
              width: 40px;
              height: 40px;
            }
          }
        `}
      </style>
    </div>
  )
}
