import { useForm, ValidationError } from "@formspree/react"
import { useEffect, useState } from "react"
import ThankYou from "./ThankYou"

const maxMbFileSize = 7 * 1024 * 1024 // 5Mb

export default function FormSubmission() {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [fields, setFields] = useState({
    age: "",
    socialMedia: "",
    email: "",
    eyeColor: "",
    first: "",
    hairColor: "",
    height: "",
    gender: "",
    last: "",
    unique: "",
    athleticAbilities: "",
    phone: "",
    clothingSize: "",
    shoeSize: "",
  })
  const [imagesObj, setImagesObj] = useState({
    headshot: "",
    sideProfile: "",
    waistUp: "",
    fullLength: "",
  })
  const [state, handleSubmit] = useForm("mpzbkpql")
  // console.log(state)
  // const [resume, setResume] = useState(false)
  useEffect(() => {
    if (!state.succeeded && isSubmitted) {
      setIsSubmitted(false)
    }
    if (state.succeeded && !isSubmitted) {
      window.scrollTo(0, 0)
    }
    if (state.submitting) {
      setImagesObj({
        headshot: "",
        sideProfile: "",
        waistUp: "",
        fullLength: "",
      })
      setFields({
        age: "",
        socialMedia: "",
        email: "",
        eyeColor: "",
        first: "",
        hairColor: "",
        height: "",
        gender: "",
        last: "",
        clothingSize: "",
        athleticAbilities: "",
        unique: "",
        phone: "",
        shoeSize: "",
      })
      setIsSubmitted(!isSubmitted)
    }
  }, [state.submitting])

  const handleImage = (e) => {
    const url = URL.createObjectURL(e.target.files[0])
    // console.log(url, e.target.files[0])
    if (e.target.files[0].size > maxMbFileSize) {
      alert("Please select a photo less than 6 MB. Thank you!")
    } else {
      setImagesObj({
        ...imagesObj,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      })
    }
  }

  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }

  if (isSubmitted) {
    return <ThankYou setIsSubmitted={() => setIsSubmitted(!isSubmitted)} isContact={false} />
  }

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} id="submissionForm" action="https://formspree.io/f/mpzbkpql" method="POST" encType="multipart/form-data">
        <div className="form-section">
          <h2>personal information</h2>
          <div className="input-group text">
            <input name="first" value={fields.first} onChange={handleChange} type="text" placeholder="first name" required={false} />
            <input name="last" value={fields.last} onChange={handleChange} type="text" placeholder="last name" required={false} />
          </div>
          <div className="input-group text">
            <input name="phone" value={fields.phone} onChange={handleChange} type="tel" placeholder="phone" required={false} />
            <input type="text" name="_gotcha" tabIndex="-1" style={{ display: "none" }} />
            <input name="gender" value={fields.gender} onChange={handleChange} type="text" placeholder="gender and pronouns" required={false} />
          </div>
          <div className="input-group text">
            <input name="email" value={fields.email} onChange={handleChange} type="email" placeholder="email" required={false} />
            <input name="socialMedia" value={fields.socialMedia} onChange={handleChange} type="text" placeholder="instagram, tiktok, and other social media" required={false} />
          </div>
        </div>
        <div className="form-section">
          <h2>attributes</h2>
          <div className="input-group text">
            <input name="age" value={fields.age} onChange={handleChange} type="number" placeholder="age" required={false} />
            <input name="height" value={fields.height} onChange={handleChange} type="text" placeholder="height" required={false} />
          </div>

          <div className="input-group text">
            <input name="shoeSize" value={fields.shoeSize} onChange={handleChange} type="text" placeholder="shoe size" required={false} />
            <input name="clothingSize" value={fields.clothingSize} onChange={handleChange} type="text" placeholder="clothing size" required={false} />
          </div>
        </div>
        <div className="form-section">
          <h2>notes</h2>
          <div className="input-group text">
            <input name="athleticAbilities" value={fields.athleticAbilities} onChange={handleChange} type="text" placeholder="athletic abilities" required={false} />
            <input name="unique" value={fields.unique} onChange={handleChange} type="text" placeholder="what makes you unique?" required={false} />
          </div>
        </div>
        {/* IMGAES */}
        <div className="form-section">
          <div className="input-group images">
            {/* HEADSHOT */}
            <div style={{ backgroundImage: `url(${imagesObj["headshot"]})` }} className="image headshot">
              <input id="headshot" style={{ margin: "0" }} accept="image/png, image/jpeg" type="file" name="headshot" onChange={handleImage} required={false} />
              <span className="plus">
                +<br></br>headshot
              </span>
            </div>

            {/* SIDE PROFILE */}
            <div style={{ backgroundImage: `url(${imagesObj["sideProfile"]})` }} className="image side-profile">
              <div className="preview-overlay"></div>
              <input
                id="sideProfile"
                style={{ margin: "0" }}
                // accept="image/png, image/jpeg"
                type="file"
                name="sideProfile"
                onChange={handleImage}
                required={false}
              />
              <span className="plus">
                +<br></br>side profile
              </span>
            </div>

            {/* WAIST UP */}
            <div style={{ backgroundImage: `url(${imagesObj["waistUp"]})` }} className="image waist-up">
              <div className="preview-overlay"></div>
              <input id="waistUp" style={{ margin: "0" }} accept="image/png, image/jpeg" type="file" name="waistUp" onChange={handleImage} required={false} />
              <span className="plus">
                +<br></br>waist up
              </span>
            </div>

            {/* FULL LENGTH */}
            <div style={{ backgroundImage: `url(${imagesObj["fullLength"]})` }} className="image full-length">
              <div className="preview-overlay"></div>
              <input id="fullLength" style={{ margin: "0" }} accept="image/png, image/jpeg" type="file" name="fullLength" onChange={handleImage} required={false} />
              <span className="plus">
                +<br></br>full length
              </span>
            </div>
          </div>
        </div>

        {/* BUTTONS */}
        <div className="form-section">
          <div className="input-group buttons">
            <button className="button" type="submit" value="submit" name="submit">
              send submission
            </button>
          </div>
        </div>
      </form>

      <style jsx>{`
        .input-group.images {
          display: flex;
          gap: 20px;
        }
        input[type="file"] {
          cursor: pointer;
          position: absolute;
          opacity: 0;
          width: inherit;
          height: inherit;
        }
        .preview-overlay {
          position: absolute;
          width: inherit;
          height: inherit;
        }
        .image {
          background-size: cover;
          background-position: center;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          width: max(350px, 20vw);
          height: 30vw;
          min-height: 400px;
          background-color: var(--cement);
        }
        .image.headshot {
          background-image: url("/circle-thin.svg");
          background-repeat: no-repeat;
          background-position: center;
          width: max(400px, 25vw);
        }
        .plus {
          color: white;
          font-size: 2em;
          font-weight: lighter;
        }
        .form-container {
          position: relative;
        }
        form {
          display: flex;
          padding: 0 var(--pad-side);
          flex-direction: column;
        }

        form h2 {
          font-size: 2.2rem;
          margin-bottom: 3rem;
        }

        .form-section {
          margin-bottom: 2rem;
        }

        .input-group {
          display: flex;
          align-items: flex-start;
        }

        .input-group.text input:first-of-type {
          margin-right: 6rem;
        }

        input:not(.button) {
          flex-grow: 1;
          margin-bottom: 4rem;
        }

        .resume-input {
          padding: 1em 5em;
          color: white;
          background-color: black;
          margin-right: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .resume-input label {
          text-align: center;
        }

        @media (max-width: 768px) {
          .input-group.text input:first-of-type {
            margin-right: 3rem;
          }
          .input-group:not(.text) {
            flex-direction: column;
            align-items: center;
          }
        }

        @media (max-width: 700px) {
          .input-group.text input:first-of-type {
            margin-right: unset;
          }
          .image.headshot {
            width: max(350px, 20vw);
          }
          .input-group.text {
            flex-direction: column;
            align-items: stretch;
          }
        }
      `}</style>
    </div>
  )
}
