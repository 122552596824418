import { logoPngs } from "../Strings"

export default function ClientLogos() {
  return (
    <div className="client-logos">
      {/* <div className="logo-column"></div> */}
      <h2>trusted by</h2>
      <div className="logo-wrapper">
        {logoPngs.map((filename, index) => (
          <img key={filename} src={`/client-logos/${filename}`} alt={filename.split(".")[0]} />
          // <div className="png-wrapper"></div>
        ))}
      </div>
      <div className="logo-wrapper">
        {logoPngs.map((filename, index) => (
          <img key={filename} src={`/client-logos/${filename}`} alt={filename.split(".")[0]} />
          // <div className="png-wrapper"></div>
        ))}
      </div>
      <div className="logo-wrapper">
        {logoPngs.map((filename, index) => (
          <img key={filename} src={`/client-logos/${filename}`} alt={filename.split(".")[0]} />
          // <div className="png-wrapper"></div>
        ))}
      </div>

      <style jsx>{`
        .client-logos h2 {
          font-size: 2.2rem;
          background: white;
          width: 100%;
          z-index: 10;
          padding-bottom: 1rem;
        }

        .client-logos {
          // overflow: hidden;
          margin: 0px auto;
        }
        .logo-wrapper img {
          width: 100px;
          height: 100%;
        }
        .logo-wrapper {
          position: absolute;
          opacity: 0;
          animation: horizontal-infinitescroll-first linear 140s 1;
          display: flex;
          flex-flow: row;
          gap: 0 50px;
          align-items: center;
          justify-content: start;
        }
        .logo-wrapper:nth-child(2) {
          transform: translateX(100vw);
          animation: horizontal-infinitescroll-second linear 155s infinite;
          animation-delay: 60s;
        }
        .logo-wrapper:nth-child(3) {
          transform: translateX(100vw);
          animation: horizontal-infinitescroll-second linear 155s infinite;
          animation-delay: 135s;
        }

        @keyframes horizontal-infinitescroll-first {
          0% {
            opacity: 1;
          }

          49% {
            opacity: 1;
          }
          50% {
            transform: translateX(calc(150px * -39));
            opacity: 0;
          }
        }
        @keyframes horizontal-infinitescroll-second {
          0% {
            opacity: 1;
          }

          49% {
            opacity: 1;
          }
          50% {
            transform: translateX(calc(150px * -39));
            opacity: 0;
          }
        }

        @media screen and (max-width: 450px) {
          .logo-wrapper {
            position: absolute;
            opacity: 0;
            animation: horizontal-infinitescroll-first linear 140s 1;
            display: flex;
            flex-flow: row;
            gap: 0 50px;
            align-items: center;
            justify-content: start;
          }
          .logo-wrapper:nth-child(2) {
            transform: translateX(100vw);
            animation: horizontal-infinitescroll-second linear 155s infinite;
            animation-delay: 65s;
          }
          .logo-wrapper:nth-child(3) {
            transform: translateX(100vw);
            animation: horizontal-infinitescroll-second linear 155s infinite;
            animation-delay: 140s;
          }
        }
      `}</style>
    </div>
  )
}
