export default function SiteFooter() {
  return (
    <>
      <footer className="desktop">
        <a href="/">
          <span id="optk">optk </span>by theoptionagency
        </a>

        <a href="mailto:hello@theoptionagency.com">hello@optkme.com</a>
        <a href="tel:+1.503.233.4244">503.233.4244</a>
      </footer>

      <style jsx>{`
        #optk {
          font-family: var(--font-2);
          font-weight: bolder;
        }
        footer {
          display: flex;
          background: black;
          padding: 50px 0;
          color: white;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          font-size: 1.25rem;
        }

        footer nav {
          display: flex;
          flex-direction: column;
        }

        @media (max-width: 700px) {
          footer {
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          footer > * {
            margin-bottom: 20px;
          }
        }
      `}</style>
    </>
  )
}
